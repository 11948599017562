$(document).ready(function(){
    $("#newsletter-signup").on("submit", function(e) {
        e.preventDefault();
        var email = $('#email');
        var emailValue = $('#email').val();
        var emptyfield = 'You did not enter an email address!';
        var VaidateEmail = 'Your email is not valid';
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var nonce = $("#_wpnonce").val();
        var referrer = $(".visually-hidden input[name='_wp_http_referer']").val();

        $(".result").text("");

        if (emailValue == "")
        {
            $("#result").removeClass("success");
            $("#result").text(emptyfield).addClass("error");
        }
        else if(!regex.test(emailValue)) {
            $("#result").removeClass("success");
            $("#result").text(VaidateEmail).addClass("error");
        }
        else {
            $("#result").text("");

            var form = $(this),
                formData = form.serialize(),
                formMethod = form.attr('method');

            var d = new Date();
            var curr_date = d.getDate();
            var curr_month = d.getMonth()+1;
            var curr_year = d.getFullYear();

            $.ajax({
                url: "https://www.crakrevenue.com/wp-admin/admin-ajax.php",
                type: "POST",
                data: "_wpnonce="+nonce+"&_wp_http_referer="+referrer+"&cp-page-url="+window.location.href+"&param[date]="+curr_date + "-" + curr_month + "-" + curr_year+"&list_parent_index=1&action=cp_add_subscriber&list_id=1&style_id=cp_id_75baa&msg_wrong_email=Please enter correct email address.&message=Thanks for subscribing. Please check your mail and confirm the subscription.&param[email]="+emailValue,
                success:function(data){
                    $("#result").text("Thank you for subscribing!").addClass("success");
                    email.val('');
                },
                error: function() {
                    $("#result").text("Your email has not been sent.").addClass("error");
                }
            });

            return false;

        }
    });
});