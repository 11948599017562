(function($) {
    'use strict';

    $(document).ready(function() {


        function SidebarOnload() {

            if (scrollTop > start && scrollTop + $('.sidebar-nav').outerHeight() + 150 < end) {
                $sidebarnav.addClass("fixed");
            }
        };

        //Table border glitch
        $("table td").css('border', '1px solid #d2d2d2');

        if (document.querySelector('#main-slider')) {


            var $wrap = $('#main-slider');
            var $device = $('#main-slider .frame li');
            var $slideWidth = $('#main-slider .frame li').innerWidth();


            //var $MainSlider = new Sly('#main-slider .frame',
            //    $.extend(Sly.defaults, {
            //
            //        horizontal: true,
            //        itemNav: 'centered',
            //        smart: 1,
            //        slidee: $('#main-slider .frame .slidee'),
            //        frame: $('#main-slider .frame'),
            //        dragHandle: false,
            //        mouseDragging: false,
            //        touchDragging: true,
            //        speed: 300,
            //        easing: 'easeOutExpo',
            //        dynamicHandle: 1,
            //        clickBar: 1,
            //        activateOn: 'click',
            //        releaseSwing: 1,
            //        startAt: 0,
            //        scrollBy: 0,
            //        elasticBounds: 1,
            //    })
            //).init();

            var mySwiper = new Swiper ('.swiper-container', {
                // Optional parameters
                direction: 'horizontal',
                loop: false,


                // If we need pagination
                pagination: '.swiper-pagination',
                touchMoveStopPropagation:false,

                // Navigation arrows
                nextButton: '.nav-item.next',
                prevButton: '.nav-item.prev',
                paginationClickable:true,
                slidesPerView:"auto",
                centeredSlides:false,
                spaceBetween:60,
                paginationBulletRender: function (swiper, index, className) {
                    return '<span class="' + className + '"></span>';
                },
                nextButton:".nav-item.next",
                prevButton:".nav-item.prev",
            });


            $wrap.find('.toStart').on('click', function () {

                var item = $(this).data('item');

                $('#main-slider .frame').sly('toStart', item);

            });

            $wrap.find('.toEnd').on('click', function () {

                var item = $(this).data('item');
                console.log(item);
                $('#main-slider .frame').sly('toEnd', item);
            });  

            $wrap.find('.toCenter').on('click', function () { 

                var item = $(this).data('item'); 

                $('#main-slider .frame').sly('toCenter', item);

              });

            $wrap.find('.btn-group li').on('click', function () {
                var button = $('.btn-group li');

                button.removeClass('active');
                $(this).addClass('active');

                noSlide = $(this).index();

            });

            $wrap.find('.nav-item.prev').on('click', function () {

                var $button = $('.btn-group li');

                if (noSlide > 0) {
                    noSlide--;

                    $('#main-slider .frame').sly('toCenter', noSlide);
                    $($button).removeClass('active');
                    $($button[noSlide]).addClass('active');
                }
            });

            $wrap.find('.nav-item.next').on('click', function () {

                var $button = $('.btn-group li');


                if (noSlide < $button.length -1) {
                    noSlide++;

                    $('#main-slider .frame').sly('toCenter', noSlide);
                    $($button).removeClass('active');
                    $($button[noSlide]).addClass('active');
                }

            });

            $(window).resize(function (event) {
                event.preventDefault();
                $('#main-slider .frame').sly('reload');
            });
        }

        if (document.querySelector('#kick-ass-container')) {

            var $frame = $('#oneperframe');
            var $wrap = $frame.parent();
            var $device = $('.oneperframe li');
            var $slideWidth = $('.oneperframe li').innerWidth();
            var noSlide = 0;

            var $kickass = new Sly('#kick-ass-container .frame',
                $.extend(Sly.defaults, {
                    horizontal: 1,
                    itemNav: 'forceCentered',
                    smart: 1,
                    slidee: $('#kick-ass-container .frame ul'),
                    frame: $('#kick-ass-container .frame'),
                    activateMiddle: 1,
                    mouseDragging: 1,
                    touchDragging: 1,
                    scrollBar: false,
                    releaseSwing: 1,
                    scrollBy: 0,
                    speed: 500,
                    elasticBounds: 1,
                    easing: 'easeOutExpo',
                    dragHandle: 1,
                    dynamicHandle: 1,
                    clickBar: 1

                })
            ).init();

            $wrap.find('.pages li').on('click', function () {
                var button = $('.pages li');

                button.removeClass('active');
                $(this).addClass('active');

                noSlide = $(this).index();
                $kickass.slideTo($slideWidth * $(this).data('number'));

            });


            $wrap.find('.controls .prev').on('click', function () {
                if (noSlide >= 0) {
                    noSlide--;
                    $kickass.slideTo($slideWidth * noSlide);
                }
            });

            $wrap.find('.controls .next').on('click', function () {
                if (noSlide < $device.length - 1) {
                    noSlide++;
                    $kickass.slideTo($slideWidth * noSlide);
                }
            });

            $(window).on('resize', function () {

                $('#kick-ass-container .frame').sly('reload');


            });

            $kickass.on('change', function () {

                var $device = $('.oneperframe li');
                var $button = $('.pages li');
                var index = $('.oneperframe li.active').index();

                if (index === $device.length - 1) {
                    $wrap.find('.controls .next').attr('disabled', 'disabled');
                } else {
                    $wrap.find('.controls .next').removeAttr('disabled', 'disabled');
                }

                if (index === 0) {
                    $wrap.find('.controls .prev').attr('disabled', 'disabled');
                } else {
                    $wrap.find('.controls .prev').removeAttr('disabled', 'disabled');
                }

                for (var n = 0; n < $device.length; n++) {
                    if ($($device[n]).hasClass('active')) {
                        $($button).removeClass('active');
                        $($button[n]).addClass('active');
                    }
                }
            });
        }

        var header = $('#masthead'),
            overlay = $('.overlay'),
            appearHeight = 600,
            fixedMenu = false;

        $(window).on('scroll', function () {
            if(!header.hasClass('blocked')){
                var scrollPos = $(window).scrollTop();

                if (scrollPos > appearHeight ) {
                    if (!header.hasClass('navbar-fixed-top') && fixedMenu == false) {
                        fixedMenu = true;
                        header.removeClass('absl');
                        header.addClass('navbar-fixed-top');
                        header.animate({'top': 0}, 250, 'linear');
                    }
                }
                else {
                    if (!header.hasClass('absl') && fixedMenu == true) {
                        fixedMenu = false;
                        header.animate({'top': '-70px'}, 250, 'linear', function () {
                            header.removeAttr('style');
                            header.removeClass('navbar-fixed-top');
                            header.addClass('absl');
                        });
                    }
                }
            }
        });

        if (document.querySelector('.single-article')) {

            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            var start = $('.single-article').offset().top
            var end = $('.single-article').offset().top + $('.single-article').innerHeight();
            var $sidebarnav = $('.sidebar-nav');
            var shareSliderPlayed = false;

            window.onload = SidebarOnload();

            $(window).on('scroll', function () {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                var start = $('.single-article').offset().top
                var end = $('.single-article').offset().top + $('.single-article').innerHeight();


                var $socialmedia = $('.single-article .shared-social-media');

                if (scrollTop > start && scrollTop + $('.single-article .shared-social-media').outerHeight() + 150 < end) {

                    $socialmedia.removeClass("fixed-bottom");
                    $socialmedia.addClass("fixed");
                    $(window).one('load', function(){
                        function getCurrentSliderAPI() {

                            var slider = jQuery('.rev_slider');
                            if(!slider.length) return false;

                            return eval('revapi' + slider.attr('id').split('rev_slider_')[1].split('_')[0]);

                        }
                        var revapi = getCurrentSliderAPI();
                        if(revapi && !shareSliderPlayed){
                            revapi.revstart();
                            shareSliderPlayed = true;
                        }
                    });
                    function getCurrentSliderAPI() {

                        var slider = jQuery('.rev_slider');
                        if(!slider.length) return false;

                        return eval('revapi' + slider.attr('id').split('rev_slider_')[1].split('_')[0]);

                    }
                    var revapi = getCurrentSliderAPI();
                    if(revapi && !shareSliderPlayed){
                        revapi.revstart();
                        shareSliderPlayed = true;
                    }

                }
                else if (scrollTop < start) {

                    $socialmedia.removeClass("fixed");
                }
                else if (scrollTop + $('.single-article .shared-social-media').outerHeight() + 150 > end) {

                    $socialmedia.addClass("fixed-bottom");
                }
            });
        }

        /**
         * Sidebar animation
         */

        if (document.querySelector('.content-aside')) {

            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            var start = $('.content-aside').offset().top
            var end = $('.content-aside').offset().top + $('.content-aside').innerHeight();
            var $sidebarnav = $('.sidebar-nav');

            window.onload = SidebarOnload();

            $(window).on('scroll', function () {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                var start = $('.content-aside').offset().top
                var end = $('.content-aside').offset().top + $('.content-aside').innerHeight();


                var $sidebarnav = $('.sidebar-nav');

                if (scrollTop > start && scrollTop + $('.sidebar-nav').outerHeight() + 150 < end) {

                    $sidebarnav.removeClass("fixed-bottom");
                    $sidebarnav.addClass("fixed");
                }
                else if (scrollTop < start) {

                    $sidebarnav.removeClass("fixed");
                }
                else if (scrollTop + $('.sidebar-nav').outerHeight() + 150 > end) {

                    $sidebarnav.addClass("fixed-bottom");
                }
            });
        }


        $(".sidebar-nav").find("a").click(function (event) {
            event.preventDefault();

            var self = $(this);
            var range = 30;
            var menu = $('.menu');
            $('html, body').animate({
                scrollTop: $(document.querySelector(self.attr('href'))).offset().top - $('#masthead').outerHeight() - range
            }, 1500);

            menu.children().removeClass('active');
            self.parent().addClass("active");
        });


        $('#nav-toggle').on('click', function() {
            this.classList.toggle('active');
            $('.menu-mobile').toggleClass('active');
        });


        if (document.querySelector('.page-template-detailed-offers')) {

            window.scrollTo(0, $('.banner').height() + 100);

        };




        if (document.querySelector('#blog-posts')) {

            var $blogpost = new Sly('#blog-posts .frame',

                $.extend(Sly.defaults, {
                        speed: 300,
                        itemNav: 'basic',
                        smart: true,
                        slidee: $('#blog-posts .frame .slidee'),
                        frame: $('#blog-posts .frame'),
                        horizontal: true,
                        scrollBy: '0',
                        scrollBar: $('.scrollbar-blog'),
                        dragHandle: true,
                        mouseDragging: true,
                        touchDragging: true
                    })
            ).init();


            $(window).resize(function (event) {
                event.preventDefault();
                $('#blog-posts .frame').sly('reload');
            });
        }

        if (document.querySelector('.submenu-container')) {

            var $submenu = new Sly('.submenu-container.frame',
                $.extend(Sly.defaults, {
                    horizontal: 1,
                    itemNav: 'centered',
                    smart: 1,
                    slidee: $('.frame .slidee'),
                    frame: $('.frame'),
                    touchDragging: true

                })
            );

            $submenu.on('load', function () {
                var $translateYElement = parseInt($('.submenu-container .slidee').css('transform').split(',')[5]);


                var $widthSlide = $('.submenu-container .slidee').width();
                var $lastlink = $('.slidee li:last-child');
                var $lastlinkOuterWidth = $lastlink.outerWidth();

                var w = $(window).width();

                var $linkthird = $('.slidee li:nth-child(3)');
                var $linkthirdOuterWidth = $linkthird.outerWidth();




                if ($linkthird.hasClass('active') && w < 767) {

                    $('#menu-submenu-affiliate').css({
                        transform: 'translateX(' + -($widthSlide-$linkthirdOuterWidth-$lastlinkOuterWidth-125) + 'px)',
                    });

                }

                if ($lastlink.hasClass('active') && w < 767) {


                    $('#menu-submenu-advertiser').css({
                        transform: 'translateX(' + -($lastlinkOuterWidth - 75) + 'px)',
                    });

                    $('#menu-submenu-affiliate').css({
                        transform: 'translateX(' + -($widthSlide-$linkthirdOuterWidth-200) + 'px)',
                    });

                }

            });

            $submenu.init();

            $(window).resize(function (event) {
                event.preventDefault();
                $('.advanced-services, .traffic, .advertiser .frame').sly('reload');
            });
        }

        /**
         * Video player
         */

        $('.modal-video-slider').on('shown.bs.modal', function (e) {
            e.preventDefault();

            for (var i = 1; i <= $('.modal-video-slider').length; i++) {
                var iframe = document.querySelector('#modal-video' + i + '  iframe');
                var player = new Vimeo.Player(iframe);

                //player.play();
            }

        });

        $('.modal-video-slider').on('hidden.bs.modal', function (e) {
            e.preventDefault();
            for (var i = 1; i <= $('.modal-video-slider').length; i++) {
                var iframe = document.querySelector('#modal-video' + i + '  iframe');
                var player = new Vimeo.Player(iframe);

                player.pause();
            }

        });

        $('.modal-video-team').on('hidden.bs.modal', function (e) {
            e.preventDefault();
            var iframe = document.querySelector('.modal-video-team iframe');
            var player = new Vimeo.Player(iframe);

            player.pause();
        });

        $('.modal-video-team').on('shown.bs.modal', function (e) {
            e.preventDefault();
            var iframe = document.querySelector('.modal-video-team iframe');
            var player = new Vimeo.Player(iframe);

            player.play();
        });


        if (document.querySelector('.single-post')) {

            $(".single-article").contents().find("iframe").addClass("embed-responsive-item").wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
            $(".single-article").contents().find('.btn-article').wrap('<div class="text-center"></div>');
        }

        //if (document.querySelector('.post-hero')) {
        //    var postHero = $('.post-hero');
        //    var range = 600;
        //    var $bannerSection = $('#main .banner');
        //    var bannerSectionHeight = $bannerSection.height();
        //    var $textSection = $('#main .post-hero-container');
        //    var textTopOffset = $textSection.offset().top;
        //
        //    $(window).on('scroll', function () {
        //
        //        var scrollTop = $(this).scrollTop();
        //        var offset = postHero.offset().top;
        //        var height = postHero.outerHeight();
        //        offset = offset + height / 2;
        //        var calc = 1 - (scrollTop / bannerSectionHeight);
        //
        //        var percentTopPos = textTopOffset / bannerSectionHeight;
        //        var topPos = percentTopPos * bannerSectionHeight;
        //
        //        $textSection.css({
        //            display: 'block',
        //            position: 'relative',
        //            zIndex: 10,
        //            top: ((scrollTop / bannerSectionHeight) * topPos) * 1.4 + 'px'
        //        });
        //
        //        postHero.css({'opacity': calc});
        //
        //        if (calc > '1') {
        //            postHero.css({'opacity': 1});
        //        } else if (calc < '0') {
        //            postHero.css({'opacity': 0});
        //        }
        //
        //    });
        //}
    });

})(jQuery);