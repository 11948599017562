(function($) {
    'use strict';

    $(document).ready(function() {

        $('.features-item-partner .features-header-partner .button, .modal-content-tablet button').on('click', function() {
            var $this = $(this);
            var parent = $this.parent().closest('.features-item-partner');
            var element = parent.find('.modal-tablet');
            var total = element.outerHeight() + 50;

            if (!element.hasClass('showup')) {
                element.addClass('showup');
                parent.css({
                    height: total
                });
            } else {
                element.removeClass('showup');
                parent.removeAttr('style');
            }
        });


        $('.features-item-services, .feature-advantage').on('click', function() {
            event.preventDefault();

            $(this).toggleClass('modal-results');

        });



        $('.fact-wrapper ul li').on('click', function() {
            event.preventDefault();

            $(this).toggleClass('open');

        });


        $('#main-slider button.info').on('click', function() {

            $(this).parent().addClass('open');

        });

        $('#main-slider button.close').on('click', function() {

            $(this).parent().removeClass('open');

        })




        $('[data-toggle="tooltip"]').tooltip();

        function animatedButton(e) {
            var parentOffset = $(this).offset(),
              relX = e.pageX - parentOffset.left,
              relY = e.pageY - parentOffset.top;

            $(this).find('span').css({
                top: relY,
                left: relX
            });
        }

        $('.btn-animated')
        .on('mouseenter', animatedButton)
        .on('mouseout', animatedButton);

    });


})(jQuery);