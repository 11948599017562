(function($) {
    'use strict';


    if ($('#product-owners').length) {

        $('#product-owners .frame2').sly(
            $.extend(Sly.defaults, {
                    speed: 300,
                    smart: true,
                    slidee: $('#product-owners .frame2 .slidee2'),
                    frame: $('#product-owners .frame2'),
                    horizontal: true,
                    scrollBy: '0',
                    startAt: '60',
                    mouseDragging: true,
                    touchDragging: true
                }
            )).init();

        $(window).resize(function (e) {
            $('#product-owners .frame2').sly('reload');
        });
    }
    if ($('#staff-member').length) {
        $('#staff-member .frame3').sly(
            $.extend(Sly.defaults, {
                    speed: 300,
                    smart: true,
                    slidee: $('#staff-member .frame3 .slidee3'),
                    frame: $('#staff-member .frame3'),
                    horizontal: true,
                    scrollBy: '0',
                    startAt: '60',
                    mouseDragging: true,
                    touchDragging: true
                }
            )).init();

        $(window).resize(function (e) {
            $('#staff-member .frame3').sly('reload');
        });

    }



})(jQuery);