var BlogPosts = function(element) {
  var self = this;
  this.element = element;

  this.init = function() {
    self.build([{}, {}, {}, {}, {}], 5);

      console.log('checked');


    $.ajax({
      url: window.CR_CONFIG.API_URL + '/blog',
      success: function(res) {
        self.build(res.data, 10);
      },
      error: function() {
        self.build([{
          title: 'Failed to load blog posts',
        }], 1);
      },
      complete: function() {
        $(self.element).find('.frame').sly(
          $.extend(Sly.defaults, {
            speed: 300,
            itemNav: 'basic',
            smart: true,
            slidee: $(self.element).find('.frame .slidee'),
            frame: $(self.element).find('.frame'),
            horizontal: true,
            scrollBy: '0',
            scrollBar: $('.scrollbar-blog'),
            dragHandle: true,
            mouseDragging: true,
            touchDragging: true
          }
        )).init();
      }
    });
  },

  this.build = function(data, limit) {
    $(this.element).html(
        $('<div>', {class: 'frame'}).append(
            $('<ul>', {class: 'slidee row-eq-height'})
        )
    );
    data.map(function(post, index) {
      var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      if (index < limit) {
        var date = new Date(post.date);
        var $date = (post.date) ? $('<span>').text(monthNames[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear()) : $('<span>', {class: 'loading'}).text('Loading');
        var $readMore = (post.link) ? $('<a>', {href: post.link}).text('Read More') : $('<a>', {class: 'loading'}).text('Loading');
        var $title = (post.title) ? $('<h3>').text(post.title) : $('<h3>', {class: 'loading'}).text('Loading');
        var $img = (post.media) ? $('<img>', {src: post.media.url, alt: post.title, width: post.media.width, height: post.media.height}) : $('<div>', {class: 'loading loading-image'});
        var $link = $('<a>', {href: post.link, target: '_blank'});

        if($title[0].textContent.length > 40) {
            $title[0].textContent = $title[0].textContent.substring(0,37)+"...";
        }

        $('<li>').append(
            $('<article>').append(
                $link.append(
                    $img,
                    $('<div>', {class: 'clearfix blog-content'}).append(
                        $date,
                        $title,
                        $readMore
                    )
                )
            )
        ).appendTo($(self.element).find('.frame .slidee'));
      }
    });
  }
  $(window).resize(function (event) {
        event.preventDefault();
        $('#blog-posts .frame').sly('reload');
  });
}